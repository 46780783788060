import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  CircularProgress,
  Button, 
  Grid
} from '@material-ui/core';
import apiRequest from 'src/utils/apiRequest';
import Page from 'src/components/Page';
import TargetRaiseCard from 'src/components/TargetRaiseCard'

// CONSTANTS
const ANALYST_URL = 'scrapers/analyst-upgrades';

// STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

// COMPONENT
const AnalystUpgradesView = () => {
  const fetchData = async () => {
    setData([]);
    setIsLoading(true);
    await apiRequest('get', ANALYST_URL)
      .then((response) => {
        const sortedArray = Object.keys(response.data)
          .map((stock) => ({
            ticker: stock,
            ...response.data[stock]
          }))
          .sort((a, b) => b.numRaises - a.numRaises);
        setData(sortedArray);
      })
      .catch((error) => {
        console.error(error);
      });
    setIsLoading(false);
  }
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [])



  return (
    <Page
      className={classes.root}
      title = "Analyst Upgrades"
    >
      <Container maxWidth={false}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            onClick={() => fetchData()}
            disabled={isLoading}
          >
            Refresh
          </Button>
        </div>

        <Box mt={3} className={classes.box}>
          {isLoading && (
            <div className={classes.loadingContainer}><CircularProgress /></div>

          )}
          <Grid container spacing={1}>
          {data.map((item) => (
            <Grid item md={2} sm={6} xs ={12}>
            <TargetRaiseCard
              key={item.ticker}
              ticker={item.ticker}
              companyName={item.company}F
              averageRaise={item.averageRaise}
              numRaises={item.numRaises}
              raises={item.raises}
            />
            </Grid>
          ))}
          </Grid>
          
        </Box>
      </Container>
    </Page>
  );
};

export default AnalystUpgradesView;
