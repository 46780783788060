import React, { useState, useEffect } from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
    Button,
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';
import apiRequest from 'src/utils/apiRequest';
// CONSTANTS
const EXTENDED_HOURS_URL = 'scrapers/extended-hours';
const types = ["gainers", "movers", "decliners"]
// COMPONENT
function ExtendedHoursCard(props) {
    const fetchData = async () => {
        setData(null)
        setIsLoading(true);
        await apiRequest('get', EXTENDED_HOURS_URL)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
        setIsLoading(false);
    }

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [type, setType] = useState(types[0]);

    useEffect(() => {
        if (data == null) {
            fetchData();
        }

    }, [type])

    return (
        <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <CardHeader title='Extended Hours' subheader='Source: Wall Street Journal'/>
            <CardContent style={{ display: 'flex'}}>
                {isLoading && (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>

                )}
                {data && (
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ticker</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Volume</TableCell>
                                <TableCell>%Change</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data[type].instruments.map((item) => (
                                <TableRow>
                                    <TableCell>{item.ticker}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.lastPrice}</TableCell>
                                    <TableCell>{item.formattedVolume}</TableCell>
                                    <TableCell>{item.percentChange}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}


            </CardContent>
            <CardActions>
                <Button
                    disabled={isLoading}
                    onClick={() => fetchData()}
                >
                    Refresh
                </Button>
                {data && types.map((key) => (
                    <Button
                        disabled={type == key}
                        onClick={() => setType(key)}
                    >
                        {key}
                    </Button>
                ))}
            </CardActions>
        </Card>
    )

}

export default ExtendedHoursCard