import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  CircularProgress,
  Button, 
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
import Top25Card from 'src/components/Top25Card';
import Eg100Card from 'src/components/Eg100Card';
import Bucket0Card from 'src/components/Bucket0Card';

// CONSTANTS
const ANALYST_URL = 'scrapers/analyst-upgrades';

// STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

// COMPONENT
const ComboListsView = () => {
  
  const classes = useStyles();



  return (
    <Page
      className={classes.root}
      title = "Combolists"
    >
      <Container maxWidth={false}>

        <Box mt={3} className={classes.box}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Bucket0Card/>
            </Grid>
            <Grid item xs={12} sm={12} md={6}> 
              <Eg100Card/>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Top25Card/>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ComboListsView;
