import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    Card,
    CardContent,
    CardHeader,
    TextField,
    Button,
    InputAdornment,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Search from '@material-ui/icons/Search';
import Page from 'src/components/Page';
import apiRequest from 'src/utils/apiRequest';
const moment = require('moment');

// CONSTANTS
const DATA_URL = 'data/';
const EARNINGS_URL = 'scrapers/recent-earnings/';
const NEWS_URL = 'scrapers/recent-news/';

// STYLES
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

// FUNCTIONS
const formatNum = (num) => {
    if (Math.abs(num) >= 1000000000) {
        return Math.round(num / 10000000) / 100 + 'B'
    } else if (Math.abs(num) >= 1000000) {
        return Math.round(num / 10000) / 100 + 'M'
    }
    return Math.round(num * 100) / 100
}

const formatPercent = (num) => {
    if (num && num !== "NaN" && (typeof num !== 'string' || !num.includes('Infinity'))) {
        const percent = parseFloat(num);
        return (
            <TableCell style={{ color: percent >= 0 ? 'green' : 'red' }}>
                {(percent > 0 ? '+' : '') + percent + '%'}
            </TableCell>
        )
    } else {
        return (
            <TableCell>
                {'-'}
            </TableCell>
        )
    }
}

const FundamentalsView = () => {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ticker, setTicker] = useState(null);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        let filler = {};
        if (ticker) {
            const formatted_ticker = ticker.trim().toUpperCase();
            await Promise.all([
                apiRequest('get', DATA_URL + formatted_ticker + '/fundamentals').then((response) => {
                    filler = { ...filler, fundamentals: response.data }
                }),
                apiRequest('get', DATA_URL + formatted_ticker + '/price').then((response) => {
                    filler = { ...filler, price: response.data }
                }),
                apiRequest('get', DATA_URL + formatted_ticker + '/profile').then((response) => {
                    filler = { ...filler, profile: response.data }
                }),
                // apiRequest('get', EARNINGS_URL + formatted_ticker).then((response) => {
                //     filler = { ...filler, recentEarnings: response.data }
                // }),
                apiRequest('get', NEWS_URL + formatted_ticker).then((response) => {
                    filler = { ...filler, recentNews: response.data }
                }),
                apiRequest('get', DATA_URL + formatted_ticker + '/price-history', {
                    params: {
                        period: 1,
                        periodType: 'year',
                        frequency: 1,
                        frequencyType: 'daily'
                    }
                }).then((response) => {
                    response.data.candles = response.data.candles.map((item) => ({...item, formattedDate: moment(item.datetime).format('MM/DD/YY')}));
                    filler = { ...filler, priceHistory: response.data}
                })
            ]).then(() => {
                setData(filler);
            }).catch(() => {
                setLoading(false);
                setError(`Could not find data for \"${formatted_ticker}\"`);
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!data) {
            fetchData();
        }
    }, [data])

    return (
        <Page
            className={classes.root}
            title="Fundamentals"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <TextField
                            style={{ width: '40%', marginLeft: '0.5em' }}
                            onChange={(e) => setTicker(e.target.value)}
                            onKeyUp={(event) => {
                                if (event.key == 'Enter') {
                                    fetchData();
                                }
                                    
                            }}
                            placeholder='Search Ticker'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </div>

                    {loading && (
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                    {error && (
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', color: 'red', margin: '0.5em' }}>
                            {error}
                        </div>
                    )}


                    <Grid container spacing={1} style={{ marginTop: '1em' }}>
                        <Grid item lg={8} md={12} sm={12} xs={12}>
                            <div>
                                {!loading && data && data.price.regularMarketPrice && (
                                    <>
                                        <Card style={{ width: '100%', margin: '0.5em' }}>
                                            <CardContent fullWidth style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <div>
                                                    <Typography variant='h3'>{data.price.symbol}</Typography>
                                                    <Typography variant='body2'>{data.price.shortName}</Typography>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Typography variant='body2' style={{ marginRight: '1em', color: data.price.regularMarketChange < 0 ? 'red' : 'green' }}>
                                                            {(data.price.regularMarketChangePercent > 0 && "+") + Math.round(data.price.regularMarketChangePercent * 10000) / 100 + "%"}
                                                        </Typography>
                                                        <Typography variant='body2' style={{ color: data.price.regularMarketChange < 0 ? 'red' : 'green' }}>
                                                            {(data.price.regularMarketChangePercent > 0 && "+") + Math.round(data.price.regularMarketChange * 100) / 100}
                                                        </Typography>
                                                    </div>
                                                    <Typography align='right' variant='h4'>{data.price.regularMarketPrice}</Typography>

                                                </div>
                                            </CardContent>
                                        </Card>
                                        <Card style={{ width: '100%', margin: '0.5em' }}>
                                            <CardHeader title='Info' />
                                            <CardContent>
                                                <Typography variant='body2'>{"Sector: " + data.profile.sector + ' (' + data.profile.industry + ')'}</Typography>
                                                <Typography variant='body2'>{"Market Cap: " + formatNum(data.price.marketCap)}</Typography>
                                                <Typography variant='body2'>{"Short Ratio: " + data.fundamentals.financialData.shortRatio}</Typography>
                                            </CardContent>
                                        </Card>
                                        <Card style={{ width: '100%', margin: '0.5em' }}>
                                            <CardHeader title='Price History' />
                                            <CardContent style={{fontSize: 'small',display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>

                                            
                                            <ResponsiveContainer width='100%' height={400}>
                                                    <LineChart
                                                        data={data.priceHistory.candles}
                                                        margin={{
                                                            top: 5,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <Tooltip/>
                                                        <XAxis dataKey="formattedDate" />
                                                        <YAxis />
                                                        <Line type="monotone" dataKey="close" stroke="#8884d8" dot={false}/>
                                                    </LineChart>
                                            </ResponsiveContainer>
                                                </CardContent>
                                        </Card>
                                        {!data.fundamentals.Error && (
                                            <Card style={{ width: '100%', margin: '0.5em' }}>
                                                <CardHeader title='Sales and Earnings' />
                                                <CardContent style={{ overflowX: 'scroll' }}>
                                                    <Table size='small'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Type</TableCell>
                                                                {data.fundamentals.revenue.quarterly.headers.map((header) => (
                                                                    <TableCell>
                                                                        {header}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Sales</TableCell>
                                                                {data.fundamentals.revenue.quarterly.data.map((item) => (
                                                                    <TableCell>
                                                                        {formatNum(item.revenue)}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Sales Growth YOY</TableCell>
                                                                {data.fundamentals.revenue.quarterly.data.map((item) => formatPercent(item.percentChangeY))}
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Sales Growth Q2Q</TableCell>
                                                                {data.fundamentals.revenue.quarterly.data.map((item) => formatPercent(item.percentChangeQ))}
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>EPS (Diluted)</TableCell>
                                                                {data.fundamentals.earnings.quarterly.data.map((item) => (
                                                                    <TableCell>
                                                                        {formatNum(item.eps)}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>EPS Growth YOY</TableCell>
                                                                {data.fundamentals.earnings.quarterly.data.map((item) => formatPercent(item.percentChangeY))}
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>EPS Growth Q2Q</TableCell>
                                                                {data.fundamentals.earnings.quarterly.data.map((item) => formatPercent(item.percentChangeQ))}
                                                            </TableRow>
                                                        </TableBody>

                                                    </Table>

                                                </CardContent>
                                            </Card>

                                        )}
                                        <Card style={{ width: '100%', margin: '0.5em' }}>
                                            <CardHeader title='Description'></CardHeader>
                                            <CardContent>
                                                <Typography variant='body2'>{data.profile.longBusinessSummary}</Typography>
                                            </CardContent>
                                        </Card>


                                    </>
                                )}

                            </div>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} >
                            <div>
                                {/* {!loading && data && data.recentEarnings && (
                                    <Card style={{ width: '100%', margin: '0.5em' }}>
                                        <CardHeader title='Recent Earnings' subheader={data.recentEarnings.quarter}></CardHeader>
                                        <CardContent>
                                            <Table size='small'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Reported</TableCell>
                                                        <TableCell>Surprise</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Sales</TableCell>
                                                        <TableCell>{formatNum(data.recentEarnings.revenue)}</TableCell>
                                                        {formatPercent(data.recentEarnings.revenueSurprise)}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Earnings</TableCell>
                                                        <TableCell>{formatNum(data.recentEarnings.earnings)}</TableCell>
                                                        {formatPercent(data.recentEarnings.earningsSurprise)}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                )} */}
                                {!loading && data  && (
                                    <Card style={{ width: '100%', margin: '0.5em' }}>
                                        {/* <CardHeader title='Earnings Whispers'></CardHeader> */}
                                        <CardContent>
                                            <iframe 
                                                src={`https://www.earningswhispers.com/epsdetails/${ticker.trim().toUpperCase()}`}
                                                width='100%'
                                                height={600}
                                                
                                            />
                                        </CardContent>
                                    </Card>
                                )}
                                {!loading && data && data.recentNews && (
                                    <Card style={{ width: '100%', margin: '0.5em' }}>
                                        <CardHeader title='Recent News'></CardHeader>
                                        <CardContent>
                                            <Table size='small'>
                                                <TableBody>
                                                    {data.recentNews.news.map((item) => (
                                                        <TableRow>
                                                            <TableCell>{'(' + item.date + ', ' + item.time + '): ' + item.title}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                )}
                                
                            </div>


                        </Grid>

                    </Grid>


                </Box>
            </Container>
        </Page>
    );
};

export default FundamentalsView;