import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyCG2lPbur6jg_lMLtbYp7UQIwIOg6X0tCg",
    authDomain: "tradeedge-75c36.firebaseapp.com",
    databaseURL: "https://tradeedge-75c36.firebaseio.com",
    projectId: "tradeedge-75c36",
    storageBucket: "tradeedge-75c36.appspot.com",
    messagingSenderId: "188127587889",
    appId: "1:188127587889:web:5b0a5dea286c8df6e32f10",
    measurementId: "G-SB1E5QMHB7"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export default firebase;