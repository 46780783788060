import React, { useState, useEffect } from 'react';
import firebase from 'src/firebase';
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import IndexCard from './IndexCard';
import ExtendedHoursCard from 'src/components/ExtendedHoursCard';
import MarketMonitorCard from 'src/components/MarketMonitorCard';
// CONSTANTS
const moment = require('moment');
const timezone = require('moment-timezone');
const DATA_URL = 'data/';

//STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

// DASHBOARD VIEW
const DashView = () => {
  const classes = useStyles();
  const [indexData, setIndexData] = useState(null);
  const [trackingData, setTrackingData] = useState(null);

  useEffect(() => {
    if (indexData == null && trackingData == null) {
      console.log("Setting up Market Monitor reference")
      let ref = firebase.database().ref('/marketMonitor');
      ref.on('value', (snapshot) => {
        const state = snapshot.val();
        let arr = [];
        if (state.data) {
          for (const [key, value] of Object.entries(state.data)) {
            arr.push({
              time: key,
              formattedTime: moment(key * 1000).format('h:mm'),
              "Up": value.bull4,
              "Down": value.bear4,
              "New High": value["New High"],
              "New Low": value["New Low"], 
              "Advancing": value["Advancing"],
              "Declining": value["Declining"], 
            })
          }
          setTrackingData({
            status: state.status,
            chartData: arr
          });
        }
        arr = [];
        if (state.indexes) {
          for (const [key, value] of Object.entries(state.indexes)) {
            arr.push({ ...value });
          }
          setIndexData(arr);
        }
      })
    }

  });

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <Grid spacing={1} container>
            <Grid container spacing={1} xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1em'}}>
              {indexData && indexData.map((item) => (
                <Grid item xs={12} sm={6} md={2}>
                  <IndexCard {...item} />
                </Grid>

              ))}
            </Grid>
            <Grid item item xs={12} sm={12} md={12}>
              <MarketMonitorCard spacing={1} data={trackingData} style={{ marginTop: '1em', marginBottom: '0.5em' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ExtendedHoursCard />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <CardContent>
                  <iframe
                    className = 'calendar'
                    src="https://www.earningswhispers.com/calendar?sb=p&d=0&t=all&v=t"
                    width='100%'
                    height={600}
                  />
                </CardContent>
              </Card>
            </Grid>
            {/* <div style={{ width: '100%', marginTop: '1em', marginBottom: '0.5em' }}>
              <MarketMonitorCard data={trackingData}/>
            </div>
            <div style={{ width: '100%', marginTop: '0.5em', marginBottom: '1em' }}>
              <ExtendedHoursCard />
            </div> */}
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default DashView;