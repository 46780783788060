import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableRow } from '@material-ui/core';

const useStyles = makeStyles((muiTheme) => ({
    mainContainer: {
        display: 'flex', 
        flexDirection: 'column',
        height: '300px', 
        margin: "0.5em",
        padding: '2px' 
    }, 
    header: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        padding: '0.5em',
        backgroundColor: 'white'
    },
    tickerInfo: {
        display: 'flex', 
        flexDirection: 'column', 
    }, 
    ticker: {
        fontSize: '20px',
        fontWeight: 500,
        color: muiTheme.palette.primary.main, 
        margin: 0
    }, 
    companyName: {
        fontSize: '12px',
        fontWeight: 400,
        color: muiTheme.palette.text.primary,
        justifyContent: 'right'
    }, 
    numRaises: {
        fontSize: '24px', 
        color: 'green', 
        fontWeight: 600
    }, 
    table: {
        backgroundColor: 'white', 
        color: muiTheme.palette.text.primary,
    }, 
    tableCell: {
        padding: '0.5em', 
        color: muiTheme.palette.text.primary
    },
    tableContainer: {
        height: '100%', 
        overflow: 'auto'
    }, 
    footerContainer: {
        height: '50px', 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '0.5em',
    }
}));

function TargetRaiseCard(props) {
    const {
        ticker, 
        companyName, 
        averageRaise, 
        numRaises,
        raises 
    } = props;
    const classes = useStyles();

    return (
        <Card className={classes.mainContainer}>
            <div className={classes.header}>
                <div className={classes.tickerInfo}>
                    <Typography className={classes.ticker}>
                        {ticker}
                    </Typography>
                    <Typography className={classes.companyName}>
                        {companyName}
                    </Typography>
                </div>
                <Typography className={classes.numRaises}>
                    {numRaises}
                </Typography>
            </div>
            <Divider/>
            <div className={classes.tableContainer}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableBody>
                        {raises.map((raise) => (
                            <TableRow key={raise.analyst+raise.date}>
                                <TableCell className={classes.tableCell}>{raise.analyst}</TableCell>
                                <TableCell className={classes.tableCell} align='right'>{raise.from}</TableCell>
                                <TableCell className={classes.tableCell} align='right'>{raise.to}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <Divider/>
            <div className={classes.footerContainer}>
                <Typography className={classes.companyName}>
                    Average Raise:
                </Typography>
                <Typography className={classes.companyName}>
                    {averageRaise}
                </Typography>
            </div>
        </Card>
    )
}

export default TargetRaiseCard;