import firebase from '../firebase';

export const signIn = async (email, password) => {
    try {
        const result = await firebase.auth().signInWithEmailAndPassword(email, password);
        return {
            result
        }
    } catch (err) {
        throw err
    }
}