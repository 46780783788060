import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Typography
} from '@material-ui/core';
import {
    ChevronUp, 
    ChevronDown, 
    Slash
  } from 'react-feather'

function IndexCard(props) {
    const {
        shortName,
        last,
        change, 
        change_pct, 
        changetype
    } = props;

    const getColor = () => {
        if (changetype =='UP') {
            return 'green'
        } else if (changetype == 'DOWN') {
            return 'red'
        } else {
            return 'grey'
        }
    }

    const getIcon = () => {
        if (changetype =='UP') {
            return <ChevronUp/>
        } else if (changetype == 'DOWN') {
            return <ChevronDown/>
        } else {
            return <Slash/>
        }
    }

    return (
        <Card style={{backgroundColor: getColor(), color: 'white'}}>
            <CardContent>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography>{shortName}</Typography>
                    <Typography>{last}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {getIcon()}
                    <Typography>{change != 'UNCH' ? change + " " + change_pct : 'UNCHANGED'}</Typography>
                </div>

            </CardContent>
        </Card>
    )
}

export default IndexCard;