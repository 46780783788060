import React, { useState, useEffect } from 'react';
import firebase from 'src/firebase';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
const moment = require('moment');

function MarketMonitorCard(props) {
    const {
        data
    } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} s={12} md={12} >
                <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <CardHeader title='4% Up vs. 4% Down' subheader={data && data.chartData.length > 0 && 'Last updated: ' + moment(data.status * 1000).format('LT')} />
                    <CardContent style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                        {data && data.chartData.length > 0 ? (
                            <ResponsiveContainer width='100%' height={400}>
                                <AreaChart
                                    data={data.chartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <XAxis dataKey="formattedTime" />
                                    <YAxis />
                                    <Area type="monotone" dataKey="Up" stroke="#26ad00" dot={true} fill="#26ad00" fillOpacity={0.1}/>
                                    <Area type="monotone" dataKey="Down" stroke="#a80202" dot={true} fill="#a80202" fillOpacity={0.1}/>
                                </AreaChart>
                            </ResponsiveContainer>


                        ) : data ? (
                            <Typography>
                                No Data Currently
                            </Typography>
                        ) : (
                            <CircularProgress />
                        )
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} s={12} md={6}>
                <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <CardHeader title='New Highs vs. New Lows' subheader={data && data.chartData.length > 0 && 'Last updated: ' + moment(data.status * 1000).format('LT')} />
                    <CardContent style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                        {data && data.chartData.length > 0 ? (
                            <ResponsiveContainer width='100%' height={400}>
                                <AreaChart
                                    data={data.chartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <XAxis dataKey="formattedTime" />
                                    <YAxis />
                                    <Area type="monotone" dataKey="New High" stroke="#26ad00" dot={true} fill="#26ad00" fillOpacity={0.1}/>
                                    <Area type="monotone" dataKey="New Low" stroke="#a80202" dot={true} fill="#a80202" fillOpacity={0.1}/>
                                </AreaChart>
                            </ResponsiveContainer>


                        ) : data ? (
                            <Typography>
                                No Data Currently
                            </Typography>
                        ) : (
                            <CircularProgress />
                        )
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} s={12} md={6}>
                <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <CardHeader title='Advancing vs Declining' subheader={data && data.chartData.length > 0 && 'Last updated: ' + moment(data.status * 1000).format('LT')} />
                    <CardContent style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                        {data && data.chartData.length > 0 ? (
                            <ResponsiveContainer width='100%' height={400}>
                                <AreaChart
                                    data={data.chartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <XAxis dataKey="formattedTime" />
                                    <YAxis />
                                    <Area type="monotone" dataKey="Advancing" stroke="#26ad00" dot={true} fill="#26ad00" fillOpacity={0.1}/>
                                    <Area type="monotone" dataKey="Declining" stroke="#a80202" dot={true} fill="#a80202" fillOpacity={0.1}/>
                                </AreaChart>
                            </ResponsiveContainer>


                        ) : data ? (
                            <Typography>
                                No Data Currently
                            </Typography>
                        ) : (
                            <CircularProgress />
                        )
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default MarketMonitorCard