import { SignalCellularNullSharp } from '@material-ui/icons';
import * as actionTypes from './actions/types';
const INITIAL_STATE = {
    user: null
}

function reducer(state = INITIAL_STATE, action){
    switch(action.type){
        case actionTypes.SET_USER:
            return {
                user: action.user,
                ...state
            };
        default:
            return state;
    }
}

export default reducer;