import React, { useState, useEffect } from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core';
import apiRequest from 'src/utils/apiRequest';

// CONSTANTS
const EG100_URL = 'combolist/eg100';
// COMPONENT
function Eg100Card(props) {
    const fetchData = async () => {
        setData(null)
        setIsLoading(true);
        await apiRequest('get', EG100_URL)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
        setIsLoading(false);
    }

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Card style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <CardHeader title='EG 100' subheader={data && `Count: ${data.size}`}/>
            <CardContent style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                {isLoading && (
                    <CircularProgress/>
                )}
                {data && data.tickers.map((item) => (
                    <Typography style={{margin: '0.5em', fontSize: '12px'}}>
                        {item}
                    </Typography>
                ))}
            </CardContent>
            <CardActions>
                <Button
                    disabled={isLoading}
                    onClick={() => fetchData()}
                >
                    Refresh
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={() => {
                        let tickers = "";
                        for (const ticker of data.tickers) {
                            tickers += ticker + " ";
                        }
                        navigator.clipboard.writeText(tickers)
                    }}
                >
                    Copy
                </Button>
            </CardActions>
        </Card>
    )

}

export default Eg100Card;
