import { getCurrentUser } from 'src/auth';
const axios = require('axios');

const API_URL = 'https://us-central1-tradeedge-75c36.cloudfunctions.net/api/';
export default async (method, link, data) => {
    const url = API_URL + link;
    const token = await getCurrentUser().getIdToken();
    if (data) {
        data.headers = {
            ...data.headers,
            authorization: 'Bearer ' + token,

        }
    } else {
        data = {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }
    }
    const response = await axios({ method, url, ...data })
    return response;
}